<template>

  <div>
    
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <!-- Table Container Card -->

    <b-card no-body>
        <b-card-body>
            <b-row>
                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <b-form-select v-model="siteData" @change="filterTable">
                        <b-form-select-option value="" disabled>Project Site</b-form-select-option>
                        <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                    </b-form-select>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input"
                placeholder="Search..."
                @input="filterTable"
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(site)="items">           
            <p class="wordBreak">{{ items.item.site.site_name | capitalize}}</p>         
        </template>

        <template #cell(location_name)="items">
            <p class="wordBreak">{{ items.item.location_name | capitalize}}</p>
        </template>
        
        <template #cell(type)="items">
            <!-- <b-badge pill :variant="items.item.type == 'pdf' ? 'success' : 'info' " class="text-capitalize"> -->
              {{ items.item.type | capitalize }}
            <!-- </b-badge> -->
        </template>

        <template #cell(updated_at)="items">
            <span>{{ items.item.updated_at | dateTime}}</span>
        </template>

        <template #cell(actions)="items">

            <b-link
                v-b-tooltip.hover.v-warning
                title="View"
                variant="outline-warning"
                @click="download(items.item.qr_url, items.item.type)"
              >
                <feather-icon icon="EyeIcon" class="mr-1 mediumSize"/>
                  
            </b-link>

            <b-link
                v-b-tooltip.hover.v-warning
                title="Delete"
                variant="outline-warning"
                @click="deleteItem(items.item._id)"
              >
                <feather-icon icon="Trash2Icon" class="mr-1 mediumSize"/>
                  
            </b-link>

        </template>

      </b-table>
      
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>

            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, VBTooltip,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BFormSelect,BFormSelectOption,BCardBody
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"

export default {
  components: {

    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BBreadcrumb,
    BFormSelect,
    BFormSelectOption
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
       tableColumns: [
            { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '20%'}},
            { key: 'location_name', label: 'Location Name', sortable: true , thStyle:  {width: '25%'}},
            { key: 'type', label: 'Type', sortable: true , thStyle:  {width: '20%'}},
            { key: 'updated_at', label: 'Generated At', sortable: true , thStyle:  {width: '20%'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%'}},
        ],
        
        items:[],
        isSortDirDesc: false,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'updated_at',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        siteData:'',
        sites:[],
    }
  },
  methods : {
    dataList(){
        return this.$store.dispatch(POST_API, {
            data:{
                page          : this.currentPage,
                keyword       : this.searchQuery,
                rowsPerPage   : this.perPage,
                sortBy        : this.sortBy,
                sortDirection : this.isSortDirDesc,
                role          : this.$store.getters.currentUser.role,
                om_sites      : this.$store.getters.currentUser.om_sites,
                site          : this.siteData != '' ? this.siteData :null,
            },
            api: '/api/active-qr'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.items        = this.$store.getters.getResults.data.docs;
                this.totalRecords = this.$store.getters.getResults.data.total;
                this.perPage      = this.$store.getters.getResults.data.limit;
                this.from         = this.$store.getters.getResults.data.from
                this.to           = this.$store.getters.getResults.data.to

                return this.items;
            }
        });
    },

    filterTable(){
      this.$refs.refUserListTable.refresh();
    },

    download(data, type){
      if(type == 'pdf'){
        window.open(data, '_blank');
      } else {
        var link = document.createElement("a");
        link.download = type;
        link.href = data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    breadCrumb(){
        var item = [{
					to:{name:'client-dashboard'},
					text: 'Dashboard',
				},{
					to:null,
					text: 'Feedback System',
				},{
					to:null,
					text: 'Settings',
				},{
          to:{name:'feedback-system-location'},
					text:'Location',
				},{
          to:{name:'location-category'},
					text:'Category',
				},{
					to:null,
					text:'Active QR',
					active:true
				}];
				return item;
    },

    allSites(){
        return this.$store.dispatch(POST_API, {
            data:{
            role:this.$store.getters.currentUser.role,
            om_sites:this.$store.getters.currentUser.om_sites,
            },
            api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.sites = this.$store.getters.getResults.data;
                // if (this.sites.length == 1 ) {
                //     this.siteData = this.sites[0]._id;
                //     this.filterTable();
                // }
                var obj = {
                    _id:'all-site',
                    site_name:'All Project Sites'
                }
                this.sites.unshift(obj);

                this.siteData = this.defaultSite(this.sites)._id;
                this.filterTable();

                return this.sites;
            }
        });
    },
    deleteItem(id){
        var msg = 'Are you sure want to delete this record?';
        var message='Active QR Deleted Successfully.';

        Swal.fire({
                  title: 'Please Confirm',
                  text: msg,
                  icon: 'warning',
                  position: 'top-center',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes',
                  cancelButtonText: 'No'
            })
          .then(result => {
            if (result.value) {
                return this.$store.dispatch(POST_API, {
                  data:{
                        id: id,
                      },
                  api:"/api/delete-active-qr",
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                          
                          Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: message,
                            showConfirmButton: false,
                            timer: 1500
                          })
                          
                          this.$refs.refUserListTable.refresh();
                        }
                    });
            }
        })
        .catch(err => {              
        })
      },

  },
  mounted(){
    this.allSites().then(()=> {
      /*this.siteData = this.$route.params.site_id ? this.$route.params.site_id : '';

      if(this.siteData){
        this.filterTable();
      }*/
    });

  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
